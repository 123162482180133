import React, { useState, useEffect } from "react";

import moment from "moment";

//import XMLParser from "react-xml-parser";

import XMLParser from "xml-js";

import axios from "axios";

import { components } from "theme-ui";

import { useForm, Controller } from "react-hook-form";

import { Link } from "gatsby";

import explogic from "../../../assets/images/subscribe/exp-yogic-transmission-img.png";

import fbicon from "../../../assets/images/subscribe/facebook-black.png";

import twittericon from "../../../assets/images/subscribe/twitter-black.png";

import googleplusicon from "../../../assets/images/subscribe/google-plus.png";

import linkinicon from "../../../assets/images/subscribe/linkedin.png";

import envelopeicon from "../../../assets/images/subscribe/envelope.png";

import whatsappicon from "../../../assets/images/subscribe/whatsapp.png";

const Beautifulthroughtcontent = () => {
  // Build Time Data Fetching

  const [storeData, setStoreData] = useState([]);
  const todayVal = moment(new Date()).format("YYYY/MM/DD");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    //console.log(data);
    let host = "https://dr.sahajmarg.org//sm_view_dr/list_xml?";
    let datavalue;
    let datalag;
    if (data != "" && data != undefined) {
      datavalue = data.years.concat(data.month);
      datalag = data.language;
      host = host + `lang=${datalag}&ct=5&filter=${datavalue}`;
    } else {
      host = host;
    }
    var self = this;
    axios
      // .get(`https://dr.sahajmarg.org//sm_view_dr/list_xml?lang=EN&ct=5&filter=${datavalue}`, {
      .get(host, {
        mode: "no-cors",
        crossDomain: true,
        headers: {
          Accept: "application/xml",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/xml; charset=utf-8",
        },
      })
      .then(function (response) {
        // var xml = new XMLParser().parseFromString(response.data);
        const xml = XMLParser.xml2js(response.data);
        // console.log(xml);
        let i = 0;

        let bigdata = xml.elements[0].elements;
        // console.log(bigdata);

        const launchOptimistic = bigdata.map((item) =>
          item.elements.map((items) => {
            if (items !== undefined) {
              let imageurl, title, content;

              if (items.name === "display-dt") {
                imageurl = items.elements[0].text;
              }
              if (items.name === "content") {
                title = items.elements[0].text;
              }

              if (items.name === "footnote") {
                content = items.elements[0].text;
              }

              let obj = {
                imageurl: imageurl,
                title: title,
                content: content,
              };
              //console.log(cname);
              return obj;
            }
          })
        );

        setStoreData(launchOptimistic);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    onSubmit();
  }, []);
  return (
    <section className="beautiful-throught-content-wrapper pad60">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div class="col-md-12 view-archieve-pad">
              <div>
                <div class="obt-rch-select-bg">
                  <span class="view-arch-txt">view Archive by</span>
                  <div class="obt-select-box-sec">
                    <span class="yr">Year</span>

                    <select
                      name="years"
                      id="years"
                      mb={3}
                      {...register("years", { required: true })}
                    >
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                    </select>
                  </div>{" "}
                  <div class="obt-select-box-sec">
                    <span class="yr">Month</span>
                    <select
                      name="month"
                      id="month"
                      mb={3}
                      {...register("month", { required: true })}
                    >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>{" "}
                  <div class="obt-select-box-sec">
                    <span class="yr">Language</span>
                    <select
                      name="language"
                      id="language"
                      mb={3}
                      {...register("language", { required: true })}
                    >
                      <option value="EN">English</option>
                      <option value="HI">Hindi</option>
                      <option value="TE">Telugu</option>
                      <option value="ML"> Malayalam</option>
                      <option value="KN">Kannada</option>
                      <option value="TA">Tamil</option>
                      <option value="MR">Marathi</option>
                      <option value="MN">Bengali</option>
                    </select>
                  </div>
                </div>
                <input
                  type="hidden"
                  id="wp_site_url"
                  value="https://heartfulness.org/en"
                />{" "}
              </div>
            </div>

            <div class="row obt-title-subscribe view-archieve-pad">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <input
                  type="submit"
                  value="Submit"
                  class="btn btn-blue btn-red float-right-button"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="row pad60">
          {storeData && storeData !== undefined ? (
            <>
              {storeData.map((data) => {
                return (
                  <div className="col-md-4 col-sm-6 obt-card obt-06-04-2022">
                    <div class="dr-item obt-day-wednesday">
                      <div class="hfn-logo-wrapper">
                        <img
                          className="img-fluid"
                          src={explogic}
                          alt="youtube"
                        />
                      </div>
                      <div className="customDataWrapperobt">
                        <div>
                          {data.map((datass) => {
                            return (
                              <div>
                                {datass.imageurl !== undefined ? (
                                  <div className="customDataobt">
                                    {datass.imageurl}
                                  </div>
                                ) : null}

                                {datass.title !== undefined ? (
                                  <div className="customDataobt">
                                    {datass.title}
                                  </div>
                                ) : null}

                                {datass.content !== undefined ? (
                                  <div className="customDataobt">
                                    {datass.content}
                                  </div>
                                ) : null}

                                <div class="sharer-div">
                                  <ul>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="https://www.facebook.com/sharer/sharer.php?u=https://heartfulness.org/en/one-beautiful-thought/06-04-2022"
                                        border="0"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className="img-fluid"
                                          src={fbicon}
                                          alt="fb"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="https://twitter.com/intent/tweet?text=The yielding heart attracts grace, happiness, cheerfulness and joy, which in turn builds relationships. - @heartful_ness&amp;url=https://heartfulness.org/en/one-beautiful-thought/06-04-2022&amp;counturl=https://heartfulness.org/en/one-beautiful-thought/06-04-2022"
                                        border="0"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className="img-fluid"
                                          src={twittericon}
                                          alt="twitter"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="https://plus.google.com/share?url=https://heartfulness.org/en/one-beautiful-thought/06-04-2022"
                                        border="0"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className="img-fluid"
                                          src={googleplusicon}
                                          alt="twitter"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="https://www.linkedin.com/shareArticle?mini=true&amp;ro=true&amp;trk=EasySocialShareButtons&amp;url=https://heartfulness.org/en/one-beautiful-thought/06-04-2022"
                                        border="0"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className="img-fluid"
                                          src={linkinicon}
                                          alt="twitter"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="mailto:?subject=One Beautiful Thought - Wednesday, April 06, 2022&amp;body=The yielding heart attracts grace, happiness, cheerfulness and joy, which in turn builds relationships."
                                        border="0"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className="img-fluid"
                                          src={envelopeicon}
                                          alt="twitter"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="whatsapp://send?text=https://heartfulness.org/en/one-beautiful-thought/06-04-2022"
                                        border="0"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className="img-fluid"
                                          src={whatsappicon}
                                          alt="twitter"
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p>Nodata</p>
          )}
        </div>
      </div>
    </section>
  );
};
export default Beautifulthroughtcontent;
