import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Beautifulthroughtbreadcreumb from "../components/subscribe/onebeautifulthrought/beautifulthrought-breadcumb";

import Beautifulthroughtcontent from "../components/subscribe/onebeautifulthrought/beautifulthrought-content";

// markup
const OnebeautifulthoughtPage = () => {
  return (
    <div>
      <Seo title="One beautiful thought archive" />

      <Layout isSticky>
        <section>
          <Beautifulthroughtbreadcreumb />
        </section>

        <section>
          <Beautifulthroughtcontent />
        </section>
      </Layout>
    </div>
  );
};

export default OnebeautifulthoughtPage;
