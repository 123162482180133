import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Beautifulthroughtbreadcreumb() {
  return (
    <div className="breadcumb-sub-page common-banner subpage-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pad40">
            <div className="inner-title text-center">
              <div classname="center-item">
                <Breadcrumb className="about-breadcumb">
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {" "} <span className="home-new-color-offerings">
                    One beautiful thought archive</span>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
